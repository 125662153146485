<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        Move Application Group to a Different Property
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="property-move-form">
          <v-autocomplete
            label="Property *"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="property_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property')"
            :error-messages="errors['property']"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="property-move-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      group: {},
      fields: {
        property_id: null,
      },
      errors: {},
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["sbpm/propertiesStore/all"];

      properties = properties.filter(
        (c) =>
          (c.status == "Marketing" || c.status == "Applications in Progress") &&
          c.id != this.$route.params.propertyId
      );

      return properties;
    },
  },

  methods: {
    open(group = null) {
      this.group = group;
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
        groupId: this.group.id,
      };

      this.$store
        .dispatch("sbpm/propertiesStore/moveApplicationToProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.property = {};
      this.fields = {
        property_id: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
