<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"> Edit Property Details </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="property-small-form">
          <v-text-field
            label="360 Viewing Link"
            v-model="fields.view_360_link"
            outlined
            background-color="white"
          ></v-text-field>

          <v-text-field
            label="Closing date"
            v-model="fields.closing_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('closing_date')"
            :error-messages="errors['closing_date']"
          ></v-text-field>

          <v-text-field
            label="Viewing date"
            v-model="fields.viewing_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('viewing_date')"
            :error-messages="errors['viewing_date']"
          ></v-text-field>

          <v-text-field
            label="Viewing time"
            v-model="fields.viewing_time"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('viewing_time')"
            :error-messages="errors['viewing_time']"
          ></v-text-field>

          <v-text-field
            label="Move In Date"
            v-model="fields.move_in_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('move_in_date')"
            :error-messages="errors['move_in_date']"
          ></v-text-field>

          <v-switch
            label="Open to applications"
            v-model="fields.open_to_applications"
            :error="errors.hasOwnProperty('open_to_applications')"
            :error-messages="errors['open_to_applications']"
            inset
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="property-small-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        viewing_date: null,
        closing_date: null,
        view_360_link: null,
        open_to_applications: false,
        move_in_date: null,
        viewing_time: null,
      },
      errors: {},
    };
  },

  methods: {
    openForm(property = null) {
      if (property !== null) {
        this.property = property;
        this.isEditing = true;

        this.fields.viewing_date = property.viewing_date;
        this.fields.closing_date = property.closing_date;
        this.fields.view_360_link = property.url_360;
        this.fields.open_to_applications = property.open_to_applications;
        this.fields.move_in_date = property.move_in_date;
        this.fields.viewing_time = property.viewing_time;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "details";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.propertyId = this.property.id;
      }

      this.$store
        .dispatch("sbpm/propertiesStore/saveProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.property = {};
      this.fields = {
        viewing_date: null,
        closing_date: null,
        view_360_link: null,
        open_to_applications: false,
        move_in_date: null,
        viewing_time: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
